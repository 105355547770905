<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <img :src="bannerImg" alt="" class="bigPicture" />
      <div class="box">
        <div class="boxOne">
          <div class="title titleOne" v-html="$t('RDService.RDS_tipOne')"></div>
          <div class="title titleTwo" v-html="$t('RDService.RDS_tipTwo')"></div>
        </div>
        <div class="boxOne boxTwo">
          <div class="boxTwoLeft">
            <div
              class="title titleThreeThree titleThree"
              v-html="$t('RDService.RDS_tipThree')"
            ></div>
            <div
              class="title titleThree titleFour"
              v-html="$t('RDService.RDS_tipFour')"
            ></div>
          </div>
          <div
            :style="
              'backgroundImage:url(' + require('@/assets/img/yanfa.png') + ')'
            "
            class="titleImg settingStyle"
          >
            <div class="titleFive">
              {{ $t("RDService.RDS_tipFive") }}
            </div>
          </div>
          <div class="boxTwoLeft boxTwoRight">
            <div
              class="title titleThree titleSix"
              v-html="$t('RDService.RDS_tipSix')"
            ></div>
            <div
              class="title titleThree titleFour titleSeven"
              v-html="$t('RDService.RDS_tipSeven')"
            ></div>
          </div>
        </div>
        <div class="boxOne">
          <div class="title titleEight" v-html="$t('RDService.RDS_tipEight')"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：行业服务-研发服务
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-16 15:21
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "行业服务",
          title_en: "Industry service",
          path: "industryService",
        },
        {
          title_zh: "研发服务",
          title_en: "R & D Service",
          path: "",
        },
      ],
      bannerImg: "",
    };
  },
  mounted() {
    this.getBigPicture();
  },
  methods: {
    /**
     * 大图
     * 刘嘉鑫
     * 2022-8-25
     */
    getBigPicture() {
      this.$request({
        url: "/Index/imgTwo",
        data: {
          type: 2, // 类型:1=人才培养,2=研发服务,3=专利服务,4=研发条件,5=科研成果,6=研发方向,7=部门设置
        },
      }).then((res) => {
        console.log("大图", res);
        this.bannerImg = res.img;
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  padding: 0 260px;
  background: #f6f6f6;
}

.bigPicture {
  width: 100%;
  height: 400px;
}

.box {
  padding: 80px 220px;

  .boxOne {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  .boxTwo {
    align-items: stretch;
  }
  .boxTwoLeft {
    width: 31%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 28px;
  }
  .boxTwoRight {
    align-items: flex-start !important;
  }
  .title {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    padding: 30px 40px;
    // width: 18%;
    text-align: center;
    background: #1a2a60;
    border: 4px solid #1a2a60;
    border-radius: 63px;
    display: inline-block;
  }

  .titleOne{
    padding: 20px 40px !important;
  }

  .titleTwo {
    margin-left: 200px;
  }

  .titleThree {
    // width: auto !important;
    // margin-bottom: 69px !important;
  }

  .titleThreeThree {
    padding: 30px 50px !important;
    margin: 0 40px 60px 0 !important;
  }

  .titleFour {
    // width: 50% !important;
  }

  .titleSeven {
    padding: 41.5px 40px !important;
  }

  .titleImg {
    width: 270px;
    height: 270px;
    margin: 0 38px;
  }

  .titleFive {
    width: 270px;
    height: 250px;
    font-size: 30px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .titleSix {
    margin: 0 0 60px 40px !important;
  }

  .titleEight{
    width: 20%;
  }
}
</style>